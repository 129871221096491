import React, { useState } from 'react';
import { Button, TextField, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

const UploadDatasetToS3 = () => {
  const [datasetName, setDatasetName] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleUpload = async () => {
    setLoading(true);
    setError(null);
    setUploadedFiles(null);

    const formData = new FormData();
    formData.append('dataset_name', datasetName);
    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]);
    }

    try {
      const response = await axios.post('/api/upload-dataset-to-s3/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUploadedFiles(response.data);
    } catch (err) {
      setError(err.response ? err.response.data : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h5" gutterBottom>
        Upload Dataset to S3
      </Typography>
      <TextField
        label="Dataset Name"
        variant="outlined"
        value={datasetName}
        onChange={(e) => setDatasetName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <input type="file" webkitdirectory="" multiple onChange={handleFileChange} />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={loading || !datasetName || files.length === 0}
        style={{ marginTop: '20px' }}
      >
        Upload Images
      </Button>
      {loading && <CircularProgress />}
      {uploadedFiles && (
        <Box mt={2}>
          <Typography variant="h6">Uploaded Files:</Typography>
          <pre>{JSON.stringify(uploadedFiles, null, 2)}</pre>
        </Box>
      )}
      {error && (
        <Typography color="error" mt={2}>
          {typeof error === 'string' ? error : JSON.stringify(error, null, 2)}
        </Typography>
      )}
    </Box>
  );
};

export default UploadDatasetToS3;
