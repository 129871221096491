// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline, CircularProgress, Box } from '@mui/material';
import { AuthProvider, useAuth } from './context/AuthContext';
import { UserProfileProvider } from './context/UserProfileContext';
import theme from './theme';
import ProtectedRoute from './components/auth/ProtectedRoute';
import { roleConfig } from './roleConfig';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// auth
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import DatabricksAWSAuth from './components/auth/DatabricksAWSAuth';
import CompleteProfile from './components/auth/CompleteProfile';
// image
import Dashboard from './components/image/Dashboard';
import Duplicates from './components/image/Duplicates';
import FaceDetection from './components/image/FaceDetection';
import CaptionGenerator from './components/image/CaptionGenerator';
import MetadataExtractor from './components/image/MetadataExtractor';
import MetadataSetter from './components/image/MetadataSetter';
import CorruptedFiles from './components/image/CorruptedFiles';
import ResizeImages from './components/image/ResizeImages';
import ConvertImages from './components/image/ConvertImages';
import SplitDataset from './components/image/SplitDataset';
import BatchImages from './components/image/BatchImages';
// marketplace
import UploadDatasetToS3 from './components/marketplace/UploadDatasetToS3';
// pages
import ImageIQPage from './pages/ImageIQPage';
import AudioIQPage from './pages/AudioIQPage';
import CulturePulseIQPage from './pages/CulturePulseIQPage';
import LandingPage from './components/landing/LandingPage';
import CreatorsLandingPage from './components/landing/CreatorsLandingPage';
import NotFoundPage from './pages/404';
// admin 
import AdminDashboard from './components/admin/AdminDashboard';
// billing
import CheckoutForm from './components/billing/CheckoutForm';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <UserProfileProvider>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/complete-profile" element={<ProtectedRoute><CompleteProfile /></ProtectedRoute>} />
              
              {/* Admin Routes */}
              <Route path="/admin/dashboard" element={<ProtectedRoute requiredRoles={roleConfig['/admin/dashboard']}><AdminDashboard /></ProtectedRoute>} />


              {/* User Routes */}
              <Route path="/dashboard" element={<ProtectedRoute requiredRoles={roleConfig['/dashboard']}><Dashboard /></ProtectedRoute>} />
              <Route path="/duplicates" element={<ProtectedRoute requiredRoles={roleConfig['/duplicates']}><Duplicates /></ProtectedRoute>} />
              <Route path="/face-detection" element={<ProtectedRoute requiredRoles={roleConfig['/face-detection']}><FaceDetection /></ProtectedRoute>} />
              <Route path="/caption-generator" element={<ProtectedRoute requiredRoles={roleConfig['/caption-generator']}><CaptionGenerator /></ProtectedRoute>} />
              <Route path="/metadata-extractor" element={<ProtectedRoute requiredRoles={roleConfig['/metadata-extractor']}><MetadataExtractor /></ProtectedRoute>} />
              <Route path="/metadata-setter" element={<ProtectedRoute requiredRoles={roleConfig['/metadata-setter']}><MetadataSetter /></ProtectedRoute>} />
              <Route path="/corrupted-files" element={<ProtectedRoute requiredRoles={roleConfig['/corrupted-files']}><CorruptedFiles /></ProtectedRoute>} />
              <Route path="/resize-images" element={<ProtectedRoute requiredRoles={roleConfig['/resize-images']}><ResizeImages /></ProtectedRoute>} />
              <Route path="/convert-images" element={<ProtectedRoute requiredRoles={roleConfig['/convert-images']}><ConvertImages /></ProtectedRoute>} />
              <Route path="/split-dataset" element={<ProtectedRoute requiredRoles={roleConfig['/split-dataset']}><SplitDataset /></ProtectedRoute>} />
              <Route path="/batch-images" element={<ProtectedRoute requiredRoles={roleConfig['/batch-images']}><BatchImages /></ProtectedRoute>} />
              <Route path="/databricks-aws-auth" element={<ProtectedRoute requiredRoles={roleConfig['/databricks-aws-auth']}><DatabricksAWSAuth /></ProtectedRoute>} />
              <Route path="/upload-dataset-to-s3" element={<ProtectedRoute requiredRoles={roleConfig['/upload-dataset-to-s3']}><UploadDatasetToS3 /></ProtectedRoute>} />
              
              {/* Other Routes */}
              <Route path="/image-iq" element={<ImageIQPage />} />
              <Route path="/audio-iq" element={<AudioIQPage />} />
              <Route path="/culture-pulse-iq" element={<CulturePulseIQPage />} />
              <Route path="creators" element={<CreatorsLandingPage />} />


              {/* Default Fallback Route */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </UserProfileProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
