import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTheme } from '@mui/system';
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemButton,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Home,
  Delete,
  FindReplace,
  Image,
  Face,
  Description,
  Folder,
  FormatSize,
  InsertPhoto,
  Assessment,
  AutoAwesome,
  Logout,
  Waves,
  PermMedia,
  Audiotrack,
  MusicNote,
  Settings,  // Import the Settings icon
} from '@mui/icons-material';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import GradientWavesIcon from './GradientWavesIcon'; // Ensure correct import path
import ProfileDialog from '../user/ProfileDialog';  // Import the ProfileDialog

const Sidebar = ({ open, onClose, onDataTypeChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dataType, setDataType] = useState('ImageIQ');
  const [isProfileDialogOpen, setProfileDialogOpen] = useState(false);  // State for profile dialog

  const handleDataTypeChange = (event) => {
    const selectedType = event.target.value;
    setDataType(selectedType);
    onDataTypeChange(selectedType);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const theme = useTheme();
  const colors = [
    theme.palette.primary.main,
    theme.palette.primary.main,
    theme.palette.accent4.main,
  ];

  const AppBarText = ({ parts, colors }) => {
    const coloredText = parts.map((part, index) => (
      <span key={index} style={{ color: colors[index % colors.length] }}>
        {part}
      </span>
    ));
    return <span>{coloredText}</span>;
  };

  const imageIQMenuItems = [
    { text: 'Workflow', icon: <AutoAwesome />, path: '/dashboard' },
    { text: 'Remove Duplicates', icon: <Delete />, path: '/duplicates' },
    { text: 'Remove Corrupted Files', icon: <FindReplace />, path: '/corrupted-files' },
    { text: 'Resize Images', icon: <Image />, path: '/resize-images' },
    { text: 'Batch Images', icon: <PermMedia />, path: '/batch-images' },
    { text: 'Convert Images', icon: <InsertPhoto />, path: '/convert-images' },
    { text: 'Detect Faces', icon: <Face />, path: '/face-detection' },
    { text: 'Generate Captions', icon: <Description />, path: '/caption-generator' },
    { text: 'Split Dataset', icon: <Assessment />, path: '/split-dataset' },
    { text: 'Extract Metadata', icon: <Folder />, path: '/metadata-extractor' },
    { text: 'Set Metadata', icon: <FormatSize />, path: '/metadata-setter' },
  ];

  const audioIQMenuItems = [
    { text: 'Workflow', icon: <AutoAwesome />, path: '/dashboard' },
    { text: 'Remove Duplicates', icon: <Delete />, path: '/duplicates' },
    { text: 'Remove Corrupted Files', icon: <FindReplace />, path: '/corrupted-files' },
    { text: 'Normalize Audio', icon: <Audiotrack />, path: '/normalize-audio' },
    { text: 'Batch Audio', icon: <MusicNote />, path: '/batch-audio' },
    { text: 'Convert Audio', icon: <Audiotrack />, path: '/convert-audio' },
    { text: 'Filter Silence', icon: <Face />, path: '/silence-detection' },
    { text: 'Generate Transcripts', icon: <Description />, path: '/transcript-generator' },
    { text: 'Split Audio', icon: <Assessment />, path: '/split-audio' },
    { text: 'Re-Encode Audio', icon: <Folder />, path: '/reencode-audio' },
    { text: 'Check Parameters', icon: <FormatSize />, path: '/check-parameters' },
  ];

  const menuItems = dataType === 'AudioIQ' ? audioIQMenuItems : imageIQMenuItems;

  return (
    <Drawer
      variant="permanent"
      open={open}
      onClose={onClose}
      sx={{ width: 240, flexShrink: 0, '& .MuiDrawer-paper': { width: 240, boxSizing: 'border-box' } }}
    >
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, marginLeft: 0 }}>
          <ListItemIcon>
            {/* <GradientWavesIcon color="primary" sx={{ fontSize: 15, mr: 0 }} /> */}
          </ListItemIcon>
          <Typography color="primary" variant="h4" sx={{ flexGrow: 1 }}>
            <AppBarText parts={['data', 'wave', 'IQ']} colors={colors} />
          </Typography>
        </Box>
      </Link>
      <Divider />
      <Box sx={{ padding: 2 }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Data Type</InputLabel>
          <Select
            value={dataType}
            onChange={handleDataTypeChange}
            label="Data Type"
          >
            <MenuItem value="ImageIQ">ImageIQ</MenuItem>
            <MenuItem value="AudioIQ">AudioIQ</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Divider />
      <List>
        {menuItems.map((item, index) => (
          <ListItemButton
            key={index}
            onClick={() => navigate(item.path)}
            selected={location.pathname === item.path}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)', // Adjust the color as needed
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)', // Adjust the color as needed
                },
              },
            }}
          >
            <ListItemIcon sx={{ color: 'text.secondary' }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} primaryTypographyProps={{ color: 'text.primary' }} />
          </ListItemButton>
        ))}
      </List>
      <Divider />
      <Box sx={{ padding: 2, position: 'absolute', bottom: 60, left: 0, right: 0 }}>
        <ListItemButton onClick={() => setProfileDialogOpen(true)}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
        <ProfileDialog open={isProfileDialogOpen} onClose={() => setProfileDialogOpen(false)} />
      </Box>
      <Box sx={{ padding: 2, position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Logout />}
          fullWidth
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
