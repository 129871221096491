import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, MenuItem, Select, IconButton, FormControlLabel, Checkbox, FormControl, InputLabel, CircularProgress } from '@mui/material';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Layout from '../shared/Layout';

const commonTags = [
  "EXIF:ImageDescription", 
  "EXIF:Artist",
  "EXIF:Copyright",
  "EXIF:Caption",
  "IPTC:By-line",
  "IPTC:Caption-Abstract",
  "IPTC:Credit",
  "IPTC:CopyrightNotice",
  "IPTC:Keywords", 
  "XMP:Copyright", 
  "XMP:WebStatement",
  "XMP:Creator",
  "XMP:Title",
  "XMP:Description",
  "XMP:Rights",
  "XMP:UsageTerms",
  "PNG:Parameters",
];

const fileTypes = ["txt", "json"];

function MetadataSetter() {
  const [folder, setFolder] = useState('');
  const [metadataInstructions, setMetadataInstructions] = useState([
    { tag: '', value: '', from_file: false, file_type: 'txt', json_key: '' }
  ]);
  const [loading, setLoading] = useState(false);

  const handleAddRow = () => {
    setMetadataInstructions([...metadataInstructions, { tag: '', value: '', from_file: false, file_type: 'txt', json_key: '' }]);
  };

  const handleRemoveRow = (index) => {
    setMetadataInstructions(metadataInstructions.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    const newInstructions = metadataInstructions.slice();
    newInstructions[index][field] = value;
    setMetadataInstructions(newInstructions);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post('/api/set_metadata/', { folder, metadata_instructions: metadataInstructions });
      console.log(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Set Metadata
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              label="Folder Path"
              value={folder}
              onChange={(e) => setFolder(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            {metadataInstructions.map((instruction, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <FormControl fullWidth margin="normal" required>
                  <InputLabel>Select Metadata Tag</InputLabel>
                  <Select
                    value={instruction.tag}
                    onChange={(e) => handleChange(index, 'tag', e.target.value)}
                    displayEmpty
                    required
                  >
                    <MenuItem value="" disabled>Select Metadata Tag</MenuItem>
                    {commonTags.map((tag) => (
                      <MenuItem key={tag} value={tag}>{tag}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  value={instruction.value}
                  onChange={(e) => handleChange(index, 'value', e.target.value)}
                  label="Value"
                  fullWidth
                  margin="normal"
                  disabled={instruction.from_file}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={instruction.from_file}
                      onChange={(e) => handleChange(index, 'from_file', e.target.checked)}
                    />
                  }
                  label="From File"
                />
                {instruction.from_file && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>File Type</InputLabel>
                    <Select
                      value={instruction.file_type}
                      onChange={(e) => handleChange(index, 'file_type', e.target.value)}
                      required
                    >
                      {fileTypes.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {instruction.from_file && instruction.file_type === 'json' && (
                  <TextField
                    value={instruction.json_key}
                    onChange={(e) => handleChange(index, 'json_key', e.target.value)}
                    label="JSON Key"
                    fullWidth
                    margin="normal"
                    required
                  />
                )}
                <IconButton onClick={() => handleRemoveRow(index)} color="secondary">
                  <RemoveCircleIcon />
                </IconButton>
                {index === metadataInstructions.length - 1 && (
                  <IconButton onClick={handleAddRow} color="primary">
                    <AddCircleIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              Set Metadata
            </Button>
          </form>

          {loading && (
            <Box mt={4} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
    </Layout>
  );
}

export default MetadataSetter;
