import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';
import axios from 'axios';
import Layout from '../shared/Layout';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function BatchImages() {
  const [inputFolder, setInputFolder] = useState('');
  const [batchSize, setBatchSize] = useState(10000);
  const [message, setMessage] = useState('');
  const [batchFolder, setBatchFolder] = useState('');

  const handleBatchSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axios.post('/api/create_batches/', {
        input_folder: inputFolder,
        batch_size: batchSize,
      });
      setMessage('Images divided into batches successfully.');
      setBatchFolder(data.batch_folder);
    } catch (error) {
      setMessage('An error occurred while dividing images into batches.');
      console.error('Error response:', error.response);
    }
  };

  return (
    <Layout>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Divide Images into Batches
          </Typography>
          <form onSubmit={handleBatchSubmit}>
            <TextField
              label="Input Folder Path"
              value={inputFolder}
              onChange={(e) => setInputFolder(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Batch Size"
              type="number"
              value={batchSize}
              onChange={(e) => setBatchSize(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <Button variant="contained" color="primary" type="submit">
              Create Batches
            </Button>
          </form>
          {message && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {message}
            </Typography>
          )}
          {batchFolder && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Batches saved in: {batchFolder}
            </Typography>
          )}
        </Box>
      </Container>
    </Layout>
  );
}

export default BatchImages;
