import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Container, Paper, CssBaseline, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { updateDoc, doc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import ParticleBackground from '../shared/ParticleBackground';
import CustomAppBar from '../shared/CustomAppBar';

const CompleteProfile = () => {
  const [name, setName] = useState('');
  const [dataType, setDataType] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!name || !dataType) {
      setError('Both fields are required');
      return;
    }
    setIsLoading(true);
    setError('');

    try {
      const user = auth.currentUser;

      if (user) {
        // Update the user's profile with the additional information
        await updateDoc(doc(db, 'profiles', user.uid), {
          name: name,
          initial_data_type: dataType,
        });
        
        await user.getIdToken(true);  // Force token refresh
        // Navigate to the dashboard after completing the profile
        navigate('/dashboard');
      } else {
        setError('User is not authenticated');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ParticleBackground />
      <CssBaseline />
      <CustomAppBar showLogin={true} />
      <Container component="main" maxWidth="xs" sx={{ position: 'relative', zIndex: 1 }}>
        <Paper elevation={10} sx={{ p: 4, mt: 10, borderRadius: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Complete Your Profile
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <FormControl variant="outlined" margin="normal" required fullWidth>
              <InputLabel>Initial Data Type</InputLabel>
              <Select
                value={dataType}
                onChange={(e) => setDataType(e.target.value)}
                label="Preferred Data Type"
              >
                <MenuItem value="Audio">Audio</MenuItem>
                <MenuItem value="Image">Image</MenuItem>
              </Select>
            </FormControl>
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save and Continue'}
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default CompleteProfile;
