import React from "react";
import { Box, Typography, Container, Button, CssBaseline } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import notFoundAnimation from "../media/404.json";
import theme from "../theme";
import CustomAppBar from "../components/shared/CustomAppBar";

const NotFoundContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: theme.spacing(8, 2),
  marginTop: theme.spacing(8),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  maxWidth: "md",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4, 2),
    marginTop: theme.spacing(4),
  },
}));

const NotFoundText = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 700,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
    marginBottom: theme.spacing(2),
  },
}));

const BackButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textTransform: "none",
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const NotFoundPage = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFoundAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
    <CustomAppBar />
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CssBaseline />
      <NotFoundContainer>
        <Lottie options={defaultOptions} height={300} width={300} />
        <NotFoundText>
          We LOVE Atlanta (404) too, but this page doesn't exist.
        </NotFoundText>
        <Link to="/" style={{ textDecoration: "none" }}>
          <BackButton variant="contained">Go Back to Home</BackButton>
        </Link>
      </NotFoundContainer>
    </Box>
    </>
  );
};

export default NotFoundPage;
