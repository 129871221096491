import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  CircularProgress,
  LinearProgress
} from '@mui/material';
import axios from 'axios';
import Layout from '../shared/Layout';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function Duplicates() {
  const [folder, setFolder] = useState('');
  const [allDuplicates, setAllDuplicates] = useState([]);
  const [displayedDuplicates, setDisplayedDuplicates] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const eventSource = new EventSource('/api/progress_stream/');
    eventSource.onmessage = (event) => {
      setProgress(parseFloat(event.data));
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const handleIdentifySubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
    setProgress(0);
    try {
      const { data } = await axios.post('/api/identify_duplicates_api/', { folder });
      if (data.duplicates && data.duplicates.length > 0) {
        setAllDuplicates(data.duplicates);
        setDisplayedDuplicates(data.duplicates.slice(0, 50));  // Show only first 50 duplicates
        setMessage('Duplicates identified successfully.');
      } else {
        setAllDuplicates([]);
        setDisplayedDuplicates([]);
        setMessage('No duplicates found.');
      }
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.error.includes('does not exist')) {
        setMessage('Invalid path');
      } else {
        setMessage('An error occurred while identifying duplicates.');
      }
      console.error('Error response:', error.response);
    }
    setLoading(false);
  };

  const handleRemoveSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
    setProgress(0);
    try {
      await axios.post('/api/remove_duplicates_api/', { folder, duplicates: allDuplicates });
      setMessage('Duplicates removed successfully.');
      setAllDuplicates([]);
      setDisplayedDuplicates([]);
    } catch (error) {
      setMessage('An error occurred while removing duplicates.');
      console.error(error);
    }
    setLoading(false);
  };

  const handleDownload = () => {
    const blob = new Blob([JSON.stringify(allDuplicates, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'duplicates.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Layout>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Identify and Remove Duplicates
          </Typography>

          <form onSubmit={handleIdentifySubmit}>
            <TextField
              label="Folder Path"
              value={folder}
              onChange={(e) => setFolder(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              Identify Duplicates
            </Button>
          </form>

          {loading && (
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress />
              {progress > 0 && (
                <Box display="flex" justifyContent="center" my={2} width="100%">
                  <LinearProgress variant="determinate" value={progress} style={{ width: '100%' }} />
                </Box>
              )}
            </Box>
          )}

          {displayedDuplicates.length > 0 && (
            <>
              <Button variant="contained" color="secondary" onClick={handleRemoveSubmit} disabled={loading}>
                Remove Duplicates
              </Button>
              <Button variant="contained" color="primary" onClick={handleDownload} disabled={loading}>
                Download Duplicates
              </Button>
              <List>
                {displayedDuplicates.map((duplicate, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={duplicate.filename} />
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {message && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {message}
            </Typography>
          )}
        </Box>
      </Container>
    </Layout>
  );
}

export default Duplicates;
