import React from 'react';
import { Stack, Typography } from '@mui/material';

const CloudAgnosticTools = () => {
  return (
    <Stack spacing={3}>
      <Typography color="text.secondary" component="p" sx={{ textAlign: 'center' }} variant="h4">
        Cloud Agnostic Tools Work With Everyone:
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 3, sm: 10 }}
        sx={{
          alignItems: 'center',
          borderTop: '1px solid var(--mui-palette-divider)',
          borderBottom: '1px solid var(--mui-palette-divider)',
          color: 'var(--mui-palette-neutral-500)',
          flexWrap: 'wrap',
          justifyContent: 'center',
          py: '20px',
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/logos/aws-platform.png`}
          alt="Amazon"
          style={{ width: '80px', height: 'auto' }}
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/logos/google-platform.png`}
          alt="Google"
          style={{ width: '80px', height: 'auto' }}
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/logos/databricks-platform.png`}
          alt="Databricks"
          style={{ width: '80px', height: 'auto' }}
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/logos/snowflake-platform.png`}
          alt="Snowflake"
          style={{ width: '80px', height: 'auto' }}
        />
      </Stack>
    </Stack>
  );
};

export default CloudAgnosticTools;
