import React from 'react';
import { Box, Container, Typography, Grid, Paper, Card, CardContent, Button } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import CustomAppBar from '../components/shared/CustomAppBar';
import ParticleBackground from '../components/shared/ParticleBackground';
import Footer from '../components/shared/Footer';

const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '60vh',
  padding: theme.spacing(4),
  textAlign: 'center',
  background: `linear-gradient(90deg, ${theme.palette.primary.semiTrans}, ${theme.palette.accent4.semiTrans})`,
  color: '#fff',
  position: 'relative',
}));

const FeatureSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: '#fbfbfb',
  position: 'relative',
}));

const ScreenshotSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: '#fff',
  position: 'relative',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const features = [
  {
    title: 'Noise Reduction',
    description: 'Automatically reduce background noise in audio files.',
  },
  {
    title: 'Speech-to-Text',
    description: 'Convert speech in audio files to text.',
  },
  {
    title: 'Audio Formatting',
    description: 'Convert audio files to different formats such as MP3, WAV, and more.',
  },
  {
    title: 'Volume Normalization',
    description: 'Normalize the volume of audio files for consistent playback.',
  },
  {
    title: 'Audio Trimming',
    description: 'Trim silent sections from audio files automatically.',
  },
  {
    title: 'Audio Enhancement',
    description: 'Enhance the quality of audio files using advanced algorithms.',
  },
];

const AudioIQPage = () => {
  const theme = useTheme();

  return (
    <>
      <ParticleBackground />
      <CustomAppBar showRegister={true} showLogin={true} />
      <HeroSection>
        <Container maxWidth="md">
          <Typography variant="h1" component="h1" gutterBottom>
            Discover AudioIQ™
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            Transform your audio files with our advanced processing tools.
          </Typography>
        </Container>
      </HeroSection>
      <FeatureSection>
        <Container maxWidth="md">
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Key Capabilities
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h3" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </FeatureSection>
      <ScreenshotSection>
        <Container maxWidth="md">
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Workflow Dashboard
          </Typography>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/screenshots/workflow-image.png`}
              alt="Workflow Dashboard"
              style={{ width: '100%', height: 'auto', borderRadius: theme.shape.borderRadius }}
            />
          </Paper>
        </Container>
      </ScreenshotSection>
      <Container maxWidth="md" sx={{ textAlign: 'center', my: 8 }}>
        <StyledButton variant="contained" href="/register">
          Get Started with AudioIQ
        </StyledButton>
      </Container>
      <Footer />
    </>
  );
};

export default AudioIQPage;
