import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import Layout from '../shared/Layout';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function ConvertImages() {
  const [folder, setFolder] = useState('');
  const [format, setFormat] = useState('jpg');
  const [message, setMessage] = useState('');
  const [outputFolder, setOutputFolder] = useState('');

  const handleConvertSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axios.post('/api/convert_images/', {
        folder,
        format,
      });
      setMessage(`Images converted to ${format.toUpperCase()} successfully.`);
      setOutputFolder(data.output_folder);
    } catch (error) {
      setMessage('An error occurred while converting images.');
      console.error('Error response:', error.response);
    }
  };

  return (
    <Layout>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Convert Images
          </Typography>
          <form onSubmit={handleConvertSubmit}>
            <TextField
              label="Folder Path"
              value={folder}
              onChange={(e) => setFolder(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Format</InputLabel>
              <Select
                value={format}
                onChange={(e) => setFormat(e.target.value)}
              >
                <MenuItem value="jpg">JPG</MenuItem>
                <MenuItem value="png">PNG</MenuItem>
                <MenuItem value="bmp">BMP</MenuItem>
                <MenuItem value="gif">GIF</MenuItem>
                <MenuItem value="tiff">TIFF</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" type="submit">
              Convert Images
            </Button>
          </form>
          {message && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {message}
            </Typography>
          )}
          {outputFolder && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Converted images saved in: {outputFolder}
            </Typography>
          )}
        </Box>
      </Container>
    </Layout>
  );
}

export default ConvertImages;
