import React from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { TextAlignCenter } from '@phosphor-icons/react';

const TaskModule = ({ index, task, onChange, onRemove }) => {
  const handleTaskChange = (field, value) => {
    onChange(index, { ...task, [field]: value });
  };

  return (
    <Box display="flex" alignItems="center" mb={2} width="100%">
        <Box width="48%" mr={2}>
            <Select
                value={task.name}
                onChange={(e) => handleTaskChange('name', e.target.value)}
                displayEmpty
                fullWidth
            >
                <MenuItem value="" disabled>Select Task</MenuItem>
                {/* <MenuItem value="countImages">Count Images</MenuItem> */}
                <MenuItem value="identifyDuplicates">Identify Duplicates</MenuItem>
                <MenuItem value="removeDuplicates">Remove Duplicates</MenuItem>
                <MenuItem value="removeCorruptImages">Remove Corrupt Images</MenuItem>
                <MenuItem value="detectFaces">Detect Faces</MenuItem>
                <MenuItem value="cropFaces">Crop Faces</MenuItem>
                <MenuItem value="detectFaceLandmarks">Detect Face Landmarks</MenuItem>
                <MenuItem value="appendLandmarks">Append Landmarks</MenuItem>
                <MenuItem value="generateCaptions">Generate Captions</MenuItem>
                <MenuItem value="resizeImages">Resize Images</MenuItem>
                <MenuItem value="convertImages">Convert Images</MenuItem>
                <MenuItem value="splitDataset">Split Dataset</MenuItem>
                <MenuItem value="extractMetadata">Extract Metadata</MenuItem>
                {/* Add more tasks as needed */}
            </Select>
        </Box>
        <Box width="48%" display="flex" alignItems="center">
        {task.name === 'resizeImages' && (
            <>
            <TextField
                label="Width"
                type="number"
                value={task.width || ''}
                onChange={(e) => handleTaskChange('width', e.target.value)}
                fullWidth
                margin='normal'
                inputProps={{ min: 256, max: 2048 }}
            />
            <TextField
                label="Height"
                type="number"
                value={task.height || ''}
                onChange={(e) => handleTaskChange('height', e.target.value)}
                fullWidth
                margin='normal'
                inputProps={{ min: 256, max: 2048 }}
            />
                <FormControlLabel
                control={
                <Checkbox
                    checked={task.maintainAspectRatio || true}
                    onChange={(e) => handleTaskChange('maintainAspectRatio', e.target.checked)}
                    color="primary"
                    disabled
                />
                }
                label="Maintain Aspect Ratio"
                style={{ marginLeft: 'auto'}}
            />
            <FormControlLabel
                control={
                <Checkbox
                    checked={task.useAI || false}
                    onChange={(e) => handleTaskChange('useAI', e.target.checked)}
                    color="primary"
                    disabled
                />
                }
                label="Use AI"
                style={{ TextAlignCenter}}
            />
            </>
        )}

        {task.name === 'convertImages' && (
            <Select
            value={task.format || ''}
            onChange={(e) => handleTaskChange('format', e.target.value)}
            fullWidth
            >
            <MenuItem value="jpg">JPG</MenuItem>
            <MenuItem value="png">PNG</MenuItem>
            <MenuItem value="bmp">BMP</MenuItem>
            {/* Add more formats as needed */}
            </Select>
        )}

        {task.name === 'generateCaptions' && (
            <TextField
            label="Output Folder"
            value={task.output_folder || ''}
            onChange={(e) => handleTaskChange('output_folder', e.target.value)}
            />
        )}

        {task.name === 'splitDataset' && (
            <>
            <TextField
                label="Train Ratio"
                type="number"
                value={task.train_ratio || 0.8}
                onChange={(e) => handleTaskChange('train_ratio', e.target.value)}
            />
            <TextField
                label="Validation Ratio"
                type="number"
                value={task.val_ratio || 0.1}
                onChange={(e) => handleTaskChange('val_ratio', e.target.value)}
            />
            <TextField
                label="Test Ratio"
                type="number"
                value={task.test_ratio || 0.1}
                onChange={(e) => handleTaskChange('test_ratio', e.target.value)}
            />
            </>
        )}

        {task.name === 'detectFaces' && (
            <TextField
            label="Output Folder"
            value={task.output_folder || ''}
            onChange={(e) => handleTaskChange('output_folder', e.target.value)}
            />
        )}

        {task.name === 'cropFaces' && (
            <TextField
            label="Output Folder"
            value={task.output_folder || ''}
            onChange={(e) => handleTaskChange('output_folder', e.target.value)}
            />
        )}

        {task.name === 'detectFaceLandmarks' && (
            <TextField
            label="Output Folder"
            value={task.output_folder || ''}
            onChange={(e) => handleTaskChange('output_folder', e.target.value)}
            />
        )}

        {task.name === 'appendLandmarks' && (
            <TextField
            label="Landmarks Data"
            value={task.data || ''}
            onChange={(e) => handleTaskChange('data', e.target.value)}
            />
        )}

        {task.name === 'extractMetadata' && (
            <TextField
            label="Output Folder"
            value={task.output_folder || ''}
            onChange={(e) => handleTaskChange('output_folder', e.target.value)}
            />
        )}


        <IconButton onClick={() => onRemove(index)} color="secondary">
            <RemoveCircleIcon />
        </IconButton>
        </Box>
    </Box>
  );
};

export default TaskModule;
