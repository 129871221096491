import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import axios from 'axios';
import Layout from '../shared/Layout';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function ResizeImages() {
  const [folder, setFolder] = useState('');
  const [width, setWidth] = useState(256);
  const [height, setHeight] = useState(256);
  const [useAI, setUseAI] = useState(false);
  const [maintainAspectRatio, setMaintainAspectRatio] = useState(false);
  const [batchSize, setBatchSize] = useState(10000);
  const [message, setMessage] = useState('');
  const [outputFolder, setOutputFolder] = useState('');

  const handleResizeSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axios.post('/api/resize_images/', {
        folder,
        size: [width, height],
        use_ai: useAI,
        maintain_aspect_ratio: maintainAspectRatio,
        batch_size: batchSize || 10000, // Default to 10000 if batchSize is empty
      });
      setMessage('Images resized successfully.');
      setOutputFolder(data.output_folder);
    } catch (error) {
      setMessage('An error occurred while resizing images.');
      console.error('Error response:', error.response);
    }
  };

  return (
    <Layout>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Resize Images
          </Typography>
          <form onSubmit={handleResizeSubmit}>
            <TextField
              label="Folder Path"
              value={folder}
              onChange={(e) => setFolder(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Width"
              value={width}
              onChange={(e) => setWidth(parseInt(e.target.value, 10))}
              fullWidth
              margin="normal"
              type="number"
              required
            />
            <TextField
              label="Height"
              value={height}
              onChange={(e) => setHeight(parseInt(e.target.value, 10))}
              fullWidth
              margin="normal"
              type="number"
              required
            />
            <TextField
              label="Batch Size"
              value={batchSize}
              onChange={(e) => setBatchSize(parseInt(e.target.value, 10))}
              fullWidth
              margin="normal"
              type="number"
              helperText="Leave empty for default batch size of 10000"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={useAI}
                  onChange={(e) => setUseAI(e.target.checked)}
                  name="useAI"
                  disabled // Disable checkbox as AI resizing is manually set to false
                />
              }
              label="Use AI for Resizing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={maintainAspectRatio}
                  onChange={(e) => setMaintainAspectRatio(e.target.checked)}
                  name="maintainAspectRatio"
                />
              }
              label="Maintain Aspect Ratio"
            />
            <Button variant="contained" color="primary" type="submit">
              Resize Images
            </Button>
          </form>
          {message && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {message}
            </Typography>
          )}
          {outputFolder && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Resized images saved in: {outputFolder}
            </Typography>
          )}
        </Box>
      </Container>
    </Layout>
  );
}

export default ResizeImages;
