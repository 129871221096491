import React from 'react';
import ParticleBackground from '../shared/ParticleBackground';
import { Box, Button, Container, Typography, Grid, CssBaseline, Card, CardContent } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { Image, Audiotrack, Analytics } from '@mui/icons-material';
import GradientWavesIcon from '../shared/GradientWavesIcon';
import CloudAgnosticTools from '../shared/CloudAgnosticTools';
import ProductivityFeatures from '../shared/ProductivityFeatures';
import RequestDemoForm from '../shared/RequestDemoForm';
import Footer from '../shared/Footer';
import CustomAppBar from '../shared/CustomAppBar';

const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '80vh',
  padding: theme.spacing(4),
  textAlign: 'center',
  zIndex: 1,
  position: 'relative',
  backgroundColor: 'transparent',
  [theme.breakpoints.down('sm')]: {
    minHeight: '60vh',
    padding: theme.spacing(2),
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.accent4.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
}));

const FeatureSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
  position: 'relative',
}));

const ProductivitySection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: theme.palette.background.default,
  zIndex: 1,
  position: 'relative',
}));

const BackedBySection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: 'transparent',
  textAlign: 'center',
  zIndex: 2,
  position: 'relative',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  },
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

const IconWrapper = styled('div')(({ theme }) => ({
  width: 80,
  height: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  borderRadius: '50%',
  backgroundColor: theme.palette.background.default,
  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
}));

const features = [
  {
    title: 'ImageIQ™',
    description: 'Turn your images into valuable datasets with our image processing tools.',
    icon: (
      <IconWrapper>
        <Image style={{ fontSize: 60 }} sx={{ color: 'primary.main' }} />
      </IconWrapper>
    ),
  },
  {
    title: 'AudioIQ™',
    description: 'Enhance and create AI-ready audio datasets with advanced audio processing tools.',
    icon: (
      <IconWrapper>
        <Audiotrack style={{ fontSize: 60 }} sx={{ color: 'accent4.main' }} />
      </IconWrapper>
    ),
  },
  {
    title: 'CulturePulseIQ™',
    description: 'Generate culturally relevant personas for more human AI development and evaluation.',
    icon: (
      <IconWrapper>
        <Analytics style={{ fontSize: 60 }} sx={{ color: 'accent5.main' }} />
      </IconWrapper>
    ),
  },
];

const CreatorsLandingPage = () => {
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', backgroundColor: theme.palette.background.default }}>
      <ParticleBackground />
      <CssBaseline />
      <CustomAppBar showLogin={true} showRegister={true} />
      <HeroSection>
        <Container maxWidth="md">
          <GradientText variant="h1" component="h1" gutterBottom>
            Turn Your Content <br />
          </GradientText>
          <Typography color="accent1" variant="h1" component="h1" gutterBottom>
            Into Valuable AI Datasets.
          </Typography>
          <Typography variant="h5" component="h5" color="textSecondary" gutterBottom>
            Sanitize, manage, and monetize your datasets with ease. DataWaveIQ is a suite of data <br /> processing tools and the swiss army knife for multimedia data.
          </Typography>
          <StyledButton variant="contained" href="/register">
            Get Started
          </StyledButton>
        </Container>
      </HeroSection>
      <FeatureSection>
        <Container maxWidth="md">
          <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ marginBottom: 8 }}>
            Curate content, build datasets faster.
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <FeatureCard>
                  {feature.icon}
                  <CardContent>
                    <Typography variant="h6" component="h3" gutterBottom align="center">
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" align="center">
                      {feature.description}
                    </Typography>
                  </CardContent>
                </FeatureCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </FeatureSection>
      <ProductivitySection>
        <Container maxWidth="md">
          <CloudAgnosticTools />
          <ProductivityFeatures />
        </Container>
      </ProductivitySection>
      <BackedBySection>
        <Container maxWidth="md">
          <RequestDemoForm />
          <Typography color={theme.palette.text.secondary} variant="h4" gutterBottom marginTop={2}>
            Backed by Techstars_
          </Typography>
        </Container>
      </BackedBySection>
      <Footer />
    </Box>
  );
};

export default CreatorsLandingPage;
