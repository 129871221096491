import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from './Sidebar';
import { styled } from '@mui/system';

const drawerWidth = 10;

const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginLeft: drawerWidth, // Match the drawer's width
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

const Layout = ({ children }) => {
  const [dataType, setDataType] = useState('ImageIQ');

  const handleDataTypeChange = (newDataType) => {
    setDataType(newDataType);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar open={true} onDataTypeChange={handleDataTypeChange} />
      <MainContent>
        {children}
      </MainContent>
    </Box>
  );
};

export default Layout;
