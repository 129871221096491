import React from 'react';
import { Box, Typography, Container, Stack, Link } from '@mui/material';
import { styled } from '@mui/system';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  borderTop: `1px solid ${theme.palette.primary}`,
  position: 'relative',
}));

const Footer = () => {
  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            &copy; {new Date().getFullYear()} DataWaveIQ. All rights reserved.
          </Typography>
          <Stack direction="row" spacing={2}>
            <Link href="#" color="inherit">
              Privacy Policy
            </Link>
            <Link href="#" color="inherit">
              Terms of Service
            </Link>
            <Link href="#waitlist-section" color="inherit">
              Contact Us
            </Link>
          </Stack>
        </Stack>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
