import React from 'react';
import { Box, Container, Typography, Grid, Paper, Button } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import CustomAppBar from '../components/shared/CustomAppBar';
import ParticleBackground from '../components/shared/ParticleBackground';
import Footer from '../components/shared/Footer';
import Icon from '@mui/icons-material/CheckCircle';
import DedupIcon from '@mui/icons-material/ContentCopy';
import AnomalyIcon from '@mui/icons-material/ReportProblem';
import ResizeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import MetadataIcon from '@mui/icons-material/Assignment';
import FaceIcon from '@mui/icons-material/Face';
import CaptionsIcon from '@mui/icons-material/Description';

const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '60vh',
  padding: theme.spacing(4),
  textAlign: 'center',
  background: `linear-gradient(90deg, ${theme.palette.primary.semiTrans}, ${theme.palette.accent4.semiTrans})`,
  color: '#fff',
  position: 'relative',
}));

const FeatureSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: theme.palette.background.offWhite,
  position: 'relative',
}));

const ScreenshotSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: '#fff',
  position: 'relative',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ImageIQPage = () => {
  const theme = useTheme();

  const features = [
    {
      title: 'Manage Metadata',
      description: 'Extract, set, and manage hidden metadata in images.',
      icon: <MetadataIcon style={{ fontSize: 50, color: theme.palette.primary.main }} />,
    },
    {
      title: 'Generate Captions',
      description: 'Generate captions for images using AI and automatically embed in images.',
      icon: <CaptionsIcon style={{ fontSize: 50, color: theme.palette.primary.main }} />, // New icon
    },
    {
      title: 'Face Detection',
      description: 'Detect faces, crop, and filter images using AI.',
      icon: <FaceIcon style={{ fontSize: 50, color: theme.palette.primary.main }} />,
    },
    {
      title: 'Deduplication',
      description: 'Detect and remove unwanted duplicates from your data.',
      icon: <DedupIcon style={{ fontSize: 50, color: theme.palette.primary.main }} />,
    },
    {
      title: 'Anomaly Detection',
      description: 'Identify anomalies and outliers in your data.',
      icon: <AnomalyIcon style={{ fontSize: 50, color: theme.palette.primary.main }} />,
    },
    {
      title: 'Resize & Convert',
      description: 'Resize and convert images while maintaining quality.',
      icon: <ResizeIcon style={{ fontSize: 50, color: theme.palette.primary.main }} />,
    },
  ];

  return (
    <>
      <ParticleBackground />
      <CustomAppBar showRegister={true} showLogin={true} />
      <HeroSection>
        <Container maxWidth="md">
          <Typography variant="h1" component="h1" gutterBottom>
            Discover ImageIQ™
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            Transform your images into datasets with our advanced processing tools.
          </Typography>
        </Container>
      </HeroSection>
      <FeatureSection>
        <Container maxWidth="lg">
          <Typography variant="h4" component="h2" gutterBottom align="center" my={4}>
            1-Click Capabilities
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box textAlign="center" p={2} border={`1px solid ${theme.palette.divider}`} borderRadius={2} boxShadow={2} sx={{ transition: 'transform 0.3s, box-shadow 0.3s', '&:hover': { transform: 'scale(1.05)', boxShadow: theme.shadows[5] }, height: '100%' }}>
                  {feature.icon}
                  <Typography variant="h6" component="h3" gutterBottom mt={2}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {feature.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </FeatureSection>
      <ScreenshotSection>
        <Container maxWidth="md">
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Workflow Dashboard
          </Typography>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/screenshots/workflow-image.png`}
              alt="Workflow Dashboard"
              style={{ width: '100%', height: 'auto', borderRadius: theme.shape.borderRadius }}
            />
          </Paper>
        </Container>
      </ScreenshotSection>
      <Container maxWidth="md" sx={{ textAlign: 'center', my: 8 }}>
        <StyledButton variant="contained" href="/register">
          Get Started with ImageIQ
        </StyledButton>
      </Container>
      <Footer />
    </>
  );
};

export default ImageIQPage;
