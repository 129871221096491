import React, { useState } from 'react';
import {
  Box, Button, Container, Typography, Paper, TextField, CircularProgress, FormControlLabel, Checkbox, Tooltip, IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Layout from '../shared/Layout';
// import axios from 'axios';
import axiosInstance from '../../axiosConfig';
import TaskModule from '../utils/TaskModule'; 

const Dashboard = () => {
  console.log('Dashboard.js: Dashboard component rendered');
  const [folder, setFolder] = useState('');
  const [tasks, setTasks] = useState([{ name: '', width: '', height: '', format: '', useAI: false }]);
  const [loading, setLoading] = useState(false);
  const [renameImages, setRenameImages] = useState(false); // State for renaming images

  const handleAddTask = () => {
    setTasks([...tasks, { name: '', width: '', height: '', format: '', useAI: false }]);
  };

  const handleRemoveTask = (index) => {
    setTasks(tasks.filter((_, i) => i !== index));
  };

  const handleTaskChange = (index, task) => {
    const newTasks = tasks.slice();
    newTasks[index] = task;
    setTasks(newTasks);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/api/process_workflow/', { folder, tasks, renameImages });
      console.log(response.data);
      // Handle the response, e.g., show a success message, navigate to another page, etc.
    } catch (error) {
      console.error(error);
      // Handle the error, e.g., show an error message
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Create Your Workflow
          </Typography>

          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6">Create an Image Processing Workflow</Typography>
            <Typography>
              Use the sidebar to navigate through the various functionalities and use this tool to chain processing actions together.
            </Typography>
          </Paper>

          <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
            <TextField
              label="Folder Path"
              value={folder}
              onChange={(e) => setFolder(e.target.value)}
              fullWidth
              margin="normal"
            />

            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={renameImages}
                    onChange={(e) => setRenameImages(e.target.checked)}
                    color="primary"
                  />
                }
                label="Rename Images during processing"
              />
              <Tooltip title="Images will be renamed as SEQUENCE_NUMBER + Original Filename">
                <IconButton>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>

            {tasks.map((task, index) => (
              <TaskModule
                key={index}
                index={index}
                task={task}
                onChange={handleTaskChange}
                onRemove={handleRemoveTask}
              />
            ))}

            <Button variant="outlined" onClick={handleAddTask} sx={{ mt: 2 }}>
              Add Task
            </Button>

            <Box mt={2} display="flex" alignItems="center">
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                Start Workflow
              </Button>
              {loading && <CircularProgress size={24} sx={{ ml: 2 }} />}
            </Box>
          </Paper>
        </Box>
      </Container>
    </Layout>
  );
};

export default Dashboard;
