import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import Layout from '../shared/Layout';

function MetadataExtractor() {
  const [folder, setFolder] = useState('');
  const [outputFolder, setOutputFolder] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleExtractMetadataSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post('/api/extract_metadata/', { folder, output_folder: outputFolder });
      setMessage('Metadata extraction completed. Combined metadata paths: ' + data.combined_metadata_paths.join(', '));
      setLoading(false);
    } catch (error) {
      setMessage('An error occurred while extracting metadata.');
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Layout>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Extract Metadata from Images
          </Typography>

          <form onSubmit={handleExtractMetadataSubmit}>
            <TextField
              label="Folder Path"
              value={folder}
              onChange={(e) => setFolder(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Output Folder Path"
              value={outputFolder}
              onChange={(e) => setOutputFolder(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              Extract Metadata
            </Button>
          </form>

          {loading && (
            <Box mt={4} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}

          {message && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {message}
            </Typography>
          )}
        </Box>
      </Container>
    </Layout>
  );
}

export default MetadataExtractor;
