import React from 'react';
import ParticleBackground from '../shared/ParticleBackground';
import { Box, Button, Container, Typography, Grid, CssBaseline, Card, CardContent } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { Image, Audiotrack, AttachMoney } from '@mui/icons-material';
import CloudAgnosticTools from '../shared/CloudAgnosticTools';
import Footer from '../shared/Footer';
import CustomAppBar from '../shared/CustomAppBar';
import WaitlistForm from '../shared/WaitlistForm';

const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '80vh',
  padding: theme.spacing(4),
  textAlign: 'center',
  zIndex: 1,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    minHeight: '60vh',
    padding: theme.spacing(2),
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.accent4.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  fontSize: '4rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
}));

const FeatureSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: '#fbfbfbd9', 
  zIndex: 1,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
  },
}));

const ProductivitySection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: '#FFFFFF',
  zIndex: 1,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
  },
}));

const BackedBySection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: 'transparent',
  textAlign: 'center',
  zIndex: 2,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  padding: theme.spacing(1, 4),
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 2),
    fontSize: '0.875rem',
  },
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  },
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  width: 80,
  height: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  borderRadius: '50%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
  [theme.breakpoints.down('sm')]: {
    width: 60,
    height: 60,
  },
}));

const features = [
  {
    title: 'ImageIQ™',
    description: 'Transform your images into valuable AI-ready datasets with our image processing toolkit.',
    icon: (
      <IconWrapper>
        <Image style={{ fontSize: 60 }} sx={{ color: 'primary.main' }} />
      </IconWrapper>
    ),
  },
  {
    title: 'AudioIQ™',
    description: 'Enhance and create AI-ready audio datasets with advanced audio processing tools.',
    icon: (
      <IconWrapper>
        <Audiotrack style={{ fontSize: 60 }} sx={{ color: 'accent4.main' }} />
      </IconWrapper>
    ),
  },
  {
    title: 'DataMarketIQ™',
    description: 'Market your datasets and earn passive income with our data marketplace manager.',
    icon: (
      <IconWrapper>
        <AttachMoney style={{ fontSize: 60 }} sx={{ color: 'accent5.main' }} />
      </IconWrapper>
    ),
  },
];

const LandingPage = () => {
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}>
      <ParticleBackground />
      <CssBaseline />
      <CustomAppBar showLogin={true} showRegister={true} />
      <HeroSection>
        <Container maxWidth="md">
          <Typography color="accent1" variant="h1" component="h1" gutterBottom>
            Unlock Your Content's <br />
          </Typography>
          <GradientText variant="h1" component="h1" gutterBottom>
            Hidden Value.
          </GradientText>
          <Typography variant="h5" component="h5" color="textSecondary" gutterBottom sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
            Transform your images and audio into valuable AI-ready datasets. Easily, create new  streams of income, without any technical expertise.
          </Typography>
          <StyledButton variant="contained" href="#waitlist-section">
            Join the Waitlist
          </StyledButton>
        </Container>
      </HeroSection>
      <FeatureSection>
        <Container maxWidth="md">
          <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ marginBottom: 8, fontSize: { xs: '2rem', sm: '2.5rem' } }}>
            Easily curate content, build datasets faster.
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <FeatureCard>
                  {feature.icon}
                  <CardContent>
                    <Typography variant="h6" component="h3" gutterBottom align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" align="center" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                      {feature.description}
                    </Typography>
                  </CardContent>
                </FeatureCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </FeatureSection>
      <ProductivitySection>
        <Container maxWidth="md">
          <CloudAgnosticTools />
        </Container>
      </ProductivitySection>
      <BackedBySection id="waitlist-section">
        <Container maxWidth="md">
          <WaitlistForm />
          <Typography color={theme.palette.text.secondary} variant="h4" gutterBottom marginTop={2} sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
            Backed by Techstars_
          </Typography>
        </Container>
      </BackedBySection>
      <Footer />
    </Box>
  );
};

export default LandingPage;
