// src/components/user/ProfileDialog.js
import React, { useContext, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, CircularProgress, Snackbar } from '@mui/material';
import { UserProfileContext } from '../../context/UserProfileContext';
import axiosInstance from '../../axiosConfig';

const ProfileDialog = ({ open, onClose }) => {
  const { profile, setProfile, loading, error } = useContext(UserProfileContext);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [nameError, setNameError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);  // State for Snackbar

  useEffect(() => {
    if (profile) {
      setUpdatedProfile(profile);
    }
  }, [profile]);

  const validateName = (name) => {
    if (!name.trim()) {
      setNameError('Name cannot be empty');
      return false;
    }
    setNameError('');
    return true;
  };

  const handleUpdate = async () => {
    if (!validateName(updatedProfile.name)) {
      return;
    }

    try {
      await axiosInstance.post('/api/profile/update/', updatedProfile);
      setProfile(updatedProfile);  // Update context with the new profile data
      setSnackbarOpen(true);  // Show the snackbar on success
      setTimeout(() => onClose(), 1500);  // Close the modal after a delay to show success message
    } catch (err) {
      console.error('Error updating profile:', err);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Profile</DialogTitle>
        <DialogContent>
          {error && <p>{error}</p>}
          <TextField
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={updatedProfile.name || ''}
            onChange={(e) => setUpdatedProfile({ ...updatedProfile, name: e.target.value })}
            error={!!nameError}
            helperText={nameError}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={updatedProfile.email || ''}
            disabled
          />
          <TextField
            margin="dense"
            label="File Limit"
            type="number"
            fullWidth
            value={updatedProfile.file_limit || ''}
            disabled
          />
          <TextField
            margin="dense"
            label="Role"
            type="text"
            fullWidth
            value={updatedProfile.role || ''}
            disabled
          />
          <TextField
            margin="dense"
            label="Files Used This Month"
            type="number"
            fullWidth
            value={updatedProfile.files_used_this_month || ''}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleUpdate} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        message="Profile updated successfully"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};

export default ProfileDialog;
