import React from 'react';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import { Waveform as WaveformIcon, UsersFour as UsersFourIcon, Infinity as InfinityIcon, ImageBroken as ImageBrokenIcon } from '@phosphor-icons/react';
import { styled } from '@mui/system';

const productivityFeatures = [
  {
    title: 'Image processing',
    description: "From deduplication to metadata to face detection, process lots of images with ease.",
    icon: <ImageBrokenIcon color='secondary' />,
    chipLabel: 'Data Cleaning',
  },
  {
    title: 'Audio processing',
    description: 'From noise reduction to speech-to-text, process all your audio files with one-click.',
    icon: <WaveformIcon color='accent1' />,
    chipLabel: 'Detect Anomalies',
  },
  {
    title: 'Cultural Relevance',
    description: "From prompt generation to persona creation, generate culturally relevant data.",
    icon: <UsersFourIcon />,
    chipLabel: 'Human-Centric',
  },
  {
    title: 'Data Augmentation',
    description: "From pre-made datasets to custom data collection, augment your multimedia datasets.",
    icon: <InfinityIcon />,
    chipLabel: 'Scalable',
  },
];

const FeatureCard = styled(Box)(({ theme }) => ({
  bgcolor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  '& svg': {
    fontSize: 60,
  },
}));

const CustomChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const ProductivityFeatures = () => {
  return (
    <Stack spacing={8} sx={{ py: '50px', alignItems: 'center' }}>
      <Stack maxWidth="700px" spacing={2} sx={{ textAlign: 'center' }}>
        <Typography variant="h3">
          Supercharge your data processing workflow.
        </Typography>
        <Typography color="text.secondary">
          Easily manage your data processing workflow with our productivity tools. Each tool is designed to help you save time and build better datasets.
        </Typography>
      </Stack>
      <Grid container spacing={3} justifyContent="center">
        {productivityFeatures.map((feature, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <FeatureCard>
              <Stack spacing={2} sx={{ p: 4, alignItems: 'center', textAlign: 'center' }}>
                <IconWrapper>{feature.icon}</IconWrapper>
                <CustomChip color="primary" label={feature.chipLabel} variant="outlined" />
                <Typography variant="h5">{feature.title}</Typography>
                <Typography color="text.secondary" variant="body2">
                  {feature.description}
                </Typography>
              </Stack>
            </FeatureCard>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default ProductivityFeatures;
