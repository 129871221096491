// src/components/auth/DatabricksAWSAuth.js

import React, { useState } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

const DatabricksAWSAuth = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const handleAuthTest = async () => {
    setLoading(true);
    setError(null);
    setData(null);

    try {
      const response = await axios.post('/api/databricks-aws-auth/');
      setData(response.data);
    } catch (err) {
      setError(err.response ? err.response.data : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h5" gutterBottom>
        Test Databricks and AWS Authentication
      </Typography>
      <Button variant="contained" color="primary" onClick={handleAuthTest} disabled={loading}>
        Test Authentication
      </Button>
      {loading && <CircularProgress />}
      {data && (
        <Box mt={2}>
          <Typography variant="h6">AWS Identity:</Typography>
          <pre>{JSON.stringify(data.aws_identity, null, 2)}</pre>
          <Typography variant="h6">Databricks Identity:</Typography>
          <pre>{JSON.stringify(data.databricks_identity, null, 2)}</pre>
        </Box>
      )}
    {error && (
    <Typography color="error" mt={2}>
        {typeof error === 'string' ? error : JSON.stringify(error, null, 2)}
    </Typography>
    )}
    </Box>
  );
};

export default DatabricksAWSAuth;
