// src/components/auth/Register.js
import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Container, Paper, Grid, CssBaseline } from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import ParticleBackground from '../shared/ParticleBackground';
import CustomAppBar from '../shared/CustomAppBar';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async () => {
    if (!email || !password) {
      setError('Both fields are required');
      return;
    }
    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      // Step 1: Register the user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Step 2: Fetch the default config values from Firestore (after authentication)
      const configDoc = await getDoc(doc(db, 'config', 'default_values'));
      let defaultRole = 'Free';
      let defaultFileLimit = 1000;

      if (configDoc.exists()) {
        const configData = configDoc.data();
        defaultRole = configData.role || defaultRole;
        defaultFileLimit = configData.file_limit || defaultFileLimit;
      }

      // Step 3: Create a Stripe customer
      const stripeResponse = await axiosInstance.post('/api/create_stripe_customer/', { email: user.email });
      const stripeCustomerId = stripeResponse.data.customer_id;

      // Step 4: Create a profile in Firestore using the default values fetched from config
      await setDoc(doc(db, 'profiles', user.uid), {
        email: user.email,
        role: defaultRole,
        file_limit: defaultFileLimit,
        files_used_this_month: 0,
        uid: user.uid,
        stripe_customer_id: stripeCustomerId,  // Store Stripe customer ID
        subscription_status: 'inactive',  // Default subscription status
        subscription_level: 'Free'  // Default subscription level
      });

      // Step 5: Call backend to set custom claims
      await axiosInstance.post('/api/set_custom_claims/', { uid: user.uid, role: 'Free' });

      // Navigate to the new page to collect additional information
      navigate('/complete-profile');
      setSuccess('User registered successfully. You can now log in.');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ParticleBackground />
      <CssBaseline />
      <CustomAppBar showLogin={true} />
      <Container component="main" maxWidth="xs">
        <Paper elevation={6} sx={{ p: 4, mt: 10, borderRadius: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Register
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!error}
              helperText={error && !email ? 'Email is required' : ''}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!error}
              helperText={error && !password ? 'Password is required' : ''}
              InputLabelProps={{ shrink: true }}
            />
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            {success && (
              <Typography color="success" sx={{ mt: 2 }}>
                {success}
              </Typography>
            )}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleRegister}
              disabled={isLoading}
            >
              {isLoading ? 'Registering...' : 'Register'}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Typography variant="body2">
                  Already have an account? <Link to="/login">Login here</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Register;
