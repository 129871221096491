// src/components/admin/AdminDashboard.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, Table, TableHead, TableRow, TableCell, TableBody, Select, MenuItem, Button } from '@mui/material';
import axiosInstance from '../../axiosConfig';
import AdminSidebar from './AdminSidebar';
// import AdminAppBar from './AdminAppBar';
import Layout from '../shared/Layout';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch users from the backend
    console.log('AdminDashboard.js: Fetching users...');
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/list_users/');
        if (Array.isArray(response.data)) {
          setUsers(response.data); // Set users if the response data is an array
        } else {
          console.error('Error: Expected an array of users but received:', response.data);
          setUsers([]); // Set an empty array if the response is not an array
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        setUsers([]); // Set an empty array on error
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleRoleChange = async (uid, newRole) => {
    if (window.confirm('Are you sure you want to change this user\'s role?')) {
      try {
        await axiosInstance.post('/api/update_role/', { uid, role: newRole });
        setUsers(users.map(user => user.uid === uid ? { ...user, role: newRole } : user));
      } catch (error) {
        console.error('Error updating user role:', error);
      }
    }
  };

  const handleDeleteUser = async (uid) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        await axiosInstance.post('/api/delete_user/', { uid });
        setUsers(users.filter(user => user.uid !== uid));
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  return (
    <Layout>
      {/* <AdminAppBar /> */}
      <AdminSidebar />
      <Container>
        <Typography variant="h4" component="h1" gutterBottom>
          Admin Dashboard
        </Typography>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          Array.isArray(users) && users.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.uid}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Select
                        value={user.role}
                        onChange={(e) => handleRoleChange(user.uid, e.target.value)}
                      >
                        <MenuItem value="Free">Free</MenuItem>
                        <MenuItem value="Hobbyist">Hobbyist</MenuItem>
                        <MenuItem value="Pro">Pro</MenuItem>
                        <MenuItem value="Enterprise">Enterprise</MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteUser(user.uid)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography>No users found.</Typography>
          )
        )}
      </Container>
    </Layout>
  );
};

export default AdminDashboard;