import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress, Slider } from '@mui/material';
import axios from 'axios';
import Layout from '../shared/Layout';

function SplitDataset() {
  const [folder, setFolder] = useState('');
  const [imageCount, setImageCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [trainRatio, setTrainRatio] = useState(80);
  const [valRatio, setValRatio] = useState(10);
  const [testRatio, setTestRatio] = useState(10);

  const handleFolderSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post('/api/count_images/', { folder });
      setImageCount(data.image_count);
      setMessage('Image count retrieved successfully.');
      setLoading(false);
    } catch (error) {
      setMessage('An error occurred while retrieving image count.');
      setLoading(false);
      console.error(error);
    }
  };

  const handleSplitSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post('/api/split_dataset/', {
        folder,
        train_ratio: trainRatio / 100,
        val_ratio: valRatio / 100,
        test_ratio: testRatio / 100,
      });
      setMessage(`Dataset split successfully. Output folder: ${data.output_folder}`);
      setLoading(false);
    } catch (error) {
      setMessage('An error occurred while splitting dataset.');
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Layout>
        <Container>
        <Box my={4}>
            <Typography variant="h4" component="h1" gutterBottom>
            Split Dataset
            </Typography>

            <form onSubmit={handleFolderSubmit}>
            <TextField
                label="Folder Path"
                value={folder}
                onChange={(e) => setFolder(e.target.value)}
                fullWidth
                margin="normal"
                required
            />
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
                Count Images
            </Button>
            </form>

            {loading && (
            <Box mt={4} display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
            )}

            {imageCount !== null && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Number of images: {imageCount}
            </Typography>
            )}

            {imageCount !== null && (
            <form onSubmit={handleSplitSubmit}>
                <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                    Split Ratios
                </Typography>
                <Typography gutterBottom>Train: {trainRatio}%</Typography>
                <Slider
                    value={trainRatio}
                    onChange={(e, newValue) => setTrainRatio(newValue)}
                    aria-labelledby="train-ratio-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={100 - valRatio - testRatio}
                />
                <Typography gutterBottom>Validation: {valRatio}%</Typography>
                <Slider
                    value={valRatio}
                    onChange={(e, newValue) => setValRatio(newValue)}
                    aria-labelledby="val-ratio-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={100 - trainRatio - testRatio}
                />
                <Typography gutterBottom>Test: {testRatio}%</Typography>
                <Slider
                    value={testRatio}
                    onChange={(e, newValue) => setTestRatio(newValue)}
                    aria-labelledby="test-ratio-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={100 - trainRatio - valRatio}
                />
                </Box>
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                Split Dataset
                </Button>
            </form>
            )}

            {message && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
                {message}
            </Typography>
            )}
        </Box>
        </Container>
    </Layout>
  );
}

export default SplitDataset;
