import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import Layout from '../shared/Layout';
// import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
// import '@tensorflow/tfjs-backend-webgl';
// import '@mediapipe/face_mesh';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function FaceDetection() {
  const [folder, setFolder] = useState('');
  const [message, setMessage] = useState('');
  const [nonFaceJsonPath, setNonFaceJsonPath] = useState('');
  const [faceJsonPath, setFaceJsonPath] = useState('');
  const [outputFolder, setOutputFolder] = useState('');
  const [cropFaces, setCropFaces] = useState(false);
  const [loading, setLoading] = useState(false);
  const [landmarks, setLandmarks] = useState([]);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem('faceDetectionState'));
    if (savedState) {
      setFolder(savedState.folder);
      setMessage(savedState.message);
      setNonFaceJsonPath(savedState.nonFaceJsonPath);
      setFaceJsonPath(savedState.faceJsonPath);
      setOutputFolder(savedState.outputFolder);
      setCropFaces(savedState.cropFaces);
      setLoading(savedState.loading);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'faceDetectionState',
      JSON.stringify({
        folder,
        message,
        nonFaceJsonPath,
        faceJsonPath,
        outputFolder,
        cropFaces,
        loading,
      })
    );
  }, [folder, message, nonFaceJsonPath, faceJsonPath, outputFolder, cropFaces, loading]);

  const loadModel = async () => {
    setLoading(true);
    try {
      // const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
      // const detector = await faceLandmarksDetection.createDetector(model);
      setLoading(false);
      // return detector;
    } catch (error) {
      setLoading(false);
      console.error('Error loading model:', error);
      return null;
    }
  };

  const detectLandmarks = async (detector) => {
    if (videoRef.current && videoRef.current.readyState >= 2) {
      try {
        const predictions = await detector.estimateFaces({
          input: videoRef.current,
          returnTensors: false,
          flipHorizontal: false,
        });
        setLandmarks(predictions);
        if (predictions.length === 0) {
          fallbackToServerSideDetection(videoRef.current);
        }
        return predictions;
      } catch (error) {
        console.error('Error estimating faces:', error);
        fallbackToServerSideDetection(videoRef.current);
      }
    }
    return [];
  };

  const fallbackToServerSideDetection = async (videoElement) => {
    setLoading(true);
    try {
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      const imageData = canvas.toDataURL('image/jpeg');
      const blob = await fetch(imageData).then((res) => res.blob());
      const formData = new FormData();
      formData.append('image', blob, 'image.jpg');

      const response = await axios.post('/api/detect_face_landmarks/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setLandmarks(response.data.landmarks);
    } catch (error) {
      console.error('Server-side detection failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStart = async () => {
    const detector = await loadModel();
    if (detector) {
      setInterval(async () => {
        await detectLandmarks(detector);
      }, 100);
    }
  };

  const handleDetectLandmarks = async () => {
    const detector = await loadModel();
    if (detector) {
      const predictions = await detectLandmarks(detector);
      await appendLandmarksToJSON(predictions);
    }
  };

  const appendLandmarksToJSON = async (predictions) => {
    if (predictions.length > 0 && faceJsonPath) {
      try {
        await axios.post('/api/append_landmarks/', {
          face_data_path: faceJsonPath,
          landmarks: predictions,
        });
        setMessage('Landmarks appended successfully.');
      } catch (error) {
        setMessage('An error occurred while appending landmarks.');
        console.error(error);
      }
    }
  };

  const handleDetectFacesSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post('/api/detect_faces/', { folder });
      setMessage('Face detection completed.');
      setNonFaceJsonPath(data.non_face_images);
      setFaceJsonPath(data.face_data);
      setOutputFolder(data.output_folder);
      handleStart(); // Initiate TensorFlow.js face landmark detection
    } catch (error) {
      setMessage('An error occurred during face detection.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMoveNonFacesSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post('/api/move_non_face_images/', { non_face_json_path: nonFaceJsonPath, output_folder: outputFolder });
      setMessage('Non-face images moved successfully.');
    } catch (error) {
      setMessage('An error occurred while moving non-face images.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCropFacesSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post('/api/crop_faces/', { face_data: faceJsonPath, folder });
      setMessage('Faces cropped and saved successfully.');
    } catch (error) {
      setMessage('An error occurred while cropping faces.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = (jsonPath, fileName) => {
    setMessage(`JSON file path: ${jsonPath}`);
  };

  return (
    <Layout>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Detect Faces and Handle Non-Face Images
          </Typography>

          <form onSubmit={handleDetectFacesSubmit}>
            <TextField
              label="Folder Path"
              value={folder}
              onChange={(e) => setFolder(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <Button variant="contained" color="primary" type="submit" disabled={loading}>
              Detect Faces
            </Button>
          </form>

          {loading && (
            <Box mt={4} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}

          {!loading && nonFaceJsonPath && faceJsonPath && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDownload(nonFaceJsonPath, 'non_faces.json')}
              >
                Show Non-Face Images JSON Path
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleDownload(faceJsonPath, 'faces.json')}
              >
                Show Face Images JSON Path
              </Button>
              <form onSubmit={handleMoveNonFacesSubmit}>
                <Button variant="contained" color="secondary" type="submit">
                  Move Non-Face Images
                </Button>
              </form>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cropFaces}
                    onChange={(e) => setCropFaces(e.target.checked)}
                    name="cropFaces"
                  />
                }
                label="Crop Faces"
              />
              {cropFaces && (
                <form onSubmit={handleCropFacesSubmit}>
                  <Button variant="contained" color="primary" type="submit">
                    Crop Faces
                  </Button>
                </form>
              )}
            </>
          )}

          {message && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {message}
            </Typography>
          )}

          {/* <Box my={4} display="flex" flexDirection="column" alignItems="center">
            <video
              ref={videoRef}
              style={{ display: 'none' }}
              width="640"
              height="480"
              autoPlay
              muted
              playsInline
            ></video>
            <canvas ref={canvasRef} width="640" height="480"></canvas>
            <Button variant="contained" color="primary" onClick={handleStart} disabled={loading}>
              Start Face Detection
            </Button>
            <Button variant="contained" color="secondary" onClick={handleDetectLandmarks} disabled={loading}>
              Detect Landmarks
            </Button>
            {landmarks.length > 0 && (
              <pre style={{ textAlign: 'left', whiteSpace: 'pre-wrap' }}>
                {JSON.stringify(landmarks, null, 2)}
              </pre>
            )}
          </Box> */}
        </Box>
      </Container>
    </Layout>
  );
}

export default FaceDetection;
