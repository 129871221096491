import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';
import axios from 'axios';
import Layout from '../shared/Layout';
import { auth } from '../../firebase';
import axiosInstance from '../../axiosConfig';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function CorruptedFiles() {
  const [folder, setFolder] = useState('');
  const [corruptedFiles, setCorruptedFiles] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true); // New state for loading auth

  useEffect(() => {
    const checkAuthState = async () => {
      const user = auth.currentUser;
      console.log('Auth state:', user);
      if (user) {
        setLoading(false); // Auth is ready
      } else {
        console.error('User not authenticated');
        setMessage('User is not authenticated.');
      }
    };

    checkAuthState();
  }, []);

  const handleIdentifySubmit = async (event) => {
    event.preventDefault();
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error('No user found');
        setMessage('User is not authenticated.');
        return;
      }

      console.log('Current user:', user);
      const token = await user.getIdToken();
      console.log('Firebase token:', token);

      const { data } = await axiosInstance.post('/api/identify_corrupted_files/', 
        { folder },
        { 
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      console.log('Response data:', data);

      if (data.corrupted_files && data.corrupted_files.length > 0) {
        setCorruptedFiles(data.corrupted_files);
        setTotalFiles(data.total_files);
        setMessage('Corrupted files identified successfully.');
      } else {
        setCorruptedFiles([]);
        setMessage('No corrupted files found.');
      }
    } catch (error) {
      setMessage('An error occurred while identifying corrupted files.');
      console.error('Error response:', error.response);
      console.error('Error details:', error);
    }
  };

  const handleRemoveSubmit = async (event) => {
    event.preventDefault();
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error('No user found');
        setMessage('User is not authenticated.');
        return;
      }

      const token = await user.getIdToken();
      console.log('Firebase token:', token);

      const { data } = await axiosInstance.post('/api/remove_corrupted_files/', 
        { folder },
        { 
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      setMessage('Corrupted files removed successfully.');
      setCorruptedFiles([]);
    } catch (error) {
      setMessage('An error occurred while removing corrupted files.');
      console.error('Error response:', error.response);
      console.error('Error details:', error);
    }
  };

  const handleDownload = () => {
    const blob = new Blob([JSON.stringify(corruptedFiles, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'corrupted_files.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <Layout>
        <Container>
          <Typography variant="h6" gutterBottom>
            Loading...
          </Typography>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Identify and Remove Corrupted Files
          </Typography>

          <form onSubmit={handleIdentifySubmit}>
            <TextField
              label="Folder Path"
              value={folder}
              onChange={(e) => setFolder(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <Button variant="contained" color="primary" type="submit">
              Identify Corrupted Files
            </Button>
          </form>

          {corruptedFiles.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom>
                Total Files: {totalFiles}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Corrupted Files: {corruptedFiles.length}
              </Typography>

              <form onSubmit={handleRemoveSubmit}>
                <Button variant="contained" color="secondary" type="submit">
                  Remove Corrupted Files
                </Button>
                <Button variant="contained" color="primary" onClick={handleDownload}>
                  Download Corrupted Files
                </Button>
              </form>
              <List>
                {corruptedFiles.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={file} />
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {message && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {message}
            </Typography>
          )}
        </Box>
      </Container>
    </Layout>
  );
}

export default CorruptedFiles;
